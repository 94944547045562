import React, { useEffect, useRef, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team1 from "../../assets/NT1.png";
import team2 from "../../assets/NT2.png";
import team3 from "../../assets/NT3.png";
import team4 from "../../assets/NT4.png";
import team5 from "../../assets/NT5.png";
import team7 from "../../assets/t7.png";
import { ObserverContext } from "../../Contexts/ObserverContext";

function Team() {
  const ref = useRef();
  const { activeSection, setActiveSection } = useContext(ObserverContext);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setActiveSection("team");
        console.log("this is updated section", activeSection);
      }
    });
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="team-section" id="team" ref={ref}>
      <h2 className="wow fadeInDown" data-wow-delay="300ms">
        Meet The Team
      </h2>
      <p className="wow fadeInDown" data-wow-delay="300ms">
        With over 153 years of combined experience, we've got a well-seasoned
        team at the helm.
      </p>
      <Slider {...settings}>
        <div>
        <div className="team-slide" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="item-container" style={{ width: "200px", margin: "0 auto" }}>
    <div className="item">
      <div
        className="image-container"
        // style={{
        //   position: "relative",
        //   height: "250px",
        //   overflow: "hidden",
        // }}
      >
       <img src={team1} alt="image" />
        {/* <img
          src={team1}
          alt="image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        /> */}
        <a
          href="https://www.linkedin.com/in/jashinder-bir-singh-3a130b27/"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin-link"
        >
          View on LinkedIn
        </a>
      </div>
      <div className="team-detail">
        <h5 style={{ fontSize: "18px" }}>Jashinder Bir Singh</h5>
        <p style={{ fontSize: "15px" }}>Founder</p>
      </div>
    </div>
  </div>
</div>

          {/* <div className="" style={{ width: "200px", marginRight: "15px" }}>
            <div className="item">
              <div
                className="image-container"
                style={{
                  position: "relative",
                  height: "250px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={team1}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <a
                  href="https://www.linkedin.com/in/jashinder-bir-singh-3a130b27/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  View on LinkedIn
                </a>
              </div>
              <div className="team-detail">
                <h5 style={{ fontSize: "18px" }}>Jashinder Bir Singh</h5>
                <p style={{ fontSize: "15px" }}>Founder</p>
              </div>
            </div>
          </div> */}
        </div>
        <div>
        <div className="team-slide" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="item-container" style={{ width: "200px", margin: "0 auto" }}>
            <div className="item">
              <div
                className="image-container"
                // style={{
                //   position: "relative",
                //   height: "250px",
                //   overflow: "hidden",
                // }}
              >
                  <img src={team2} alt="image" />
                {/* <img
                  src={team2}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                /> */}
                <a
                  href="https://www.linkedin.com/in/chandandeep-kaur-8378b9106/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  View on LinkedIn
                </a>
              </div>
              <div className="team-detail">
                <h5 style={{ fontSize: "18px" }}>Chandandeep Kaur</h5>
                <p style={{ fontSize: "15px" }}>Director Co-Founder</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div>
        <div className="team-slide" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="item-container" style={{ width: "200px", margin: "0 auto" }}>
            <div className="item">
              <div
                className="image-container"
                // style={{
                //   position: "relative",
                //   height: "250px",
                //   overflow: "hidden",
                // }}
              >
                  <img src={team4} alt="image" />
                {/* <img
                  src={team4}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                /> */}
                <a
                  href="https://www.linkedin.com/in/onkar-dhar-6726a518/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  View on LinkedIn
                </a>
              </div>
              <div className="team-detail">
                <h5 style={{ fontSize: "18px" }}>Onkar Dhar</h5>
                <p style={{ fontSize: "15px" }}>Director Sales</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div>
        <div className="team-slide" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="item-container" style={{ width: "200px", margin: "0 auto" }}>
            <div className="item">
              <div
                className="image-container"
                // style={{
                //   position: "relative",
                //   height: "250px",
                //   overflow: "hidden",
                // }}
              >
                  <img src={team5} alt="image" />
                {/* <img
                  src={team5}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                /> */}
              </div>
              <div className="team-detail">
                <h5 style={{ fontSize: "18px" }}>Rajan Vasudev</h5>
                <p style={{ fontSize: "15px" }}>Director Finance</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div>
        <div className="team-slide" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="item-container" style={{ width: "200px", margin: "0 auto" }}>
            <div className="item">
              <div
                className="image-container"
                // style={{
                //   position: "relative",
                //   height: "250px",
                //   overflow: "hidden",
                // }}
              >
                  <img src={team3} alt="image" />
                {/* <img
                  src={team3}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                /> */}
              </div>
              <div className="team-detail">
                <h5 style={{ fontSize: "18px" }}>Ashwani Prabhakar</h5>
                <p style={{ fontSize: "15px" }}>
                  General Manager Delivery
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div>
        <div className="team-slide" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="item-container" style={{ width: "200px", margin: "0 auto" }}>
            <div className="item">
              <div
                className="image-container"
                // style={{
                //   position: "relative",
                //   height: "250px",
                //   overflow: "hidden",
                // }}
              >
                  <img src={team7} alt="image" />
                {/* <img
                  src={team7}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                /> */}
                <a
                  href="https://www.linkedin.com/in/yashkirat-singh-075074109/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  View on LinkedIn
                </a>
              </div>
              <div className="team-detail">
                <h5 style={{ fontSize: "18px" }}>Yashkirat Singh</h5>
                <p style={{ fontSize: "15px" }}>Chief Strategy Officer</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </Slider>
    </section>
  );
}

export default Team;
