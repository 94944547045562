import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
// import logo from "../../assets/ai.jpg.png";
import latestlogo from '../../assets/latestLogo.png';
import { useContext, useEffect, useRef, useState } from "react";
import IntersectionObserverHook from "../../CustomHooks/IntersectionObserverHook";
import { ObserverContext } from "../../Contexts/ObserverContext";
import * as _ from 'lodash';

function Navbar({navigation}) {
  const {
   //  serviceRef,
   //  verticalsRef,
   //  methodologyRef,
   //  contactRef,
   //  sliderRef,
   //  teamRef,
   //  startPortfolioRef,
    activeSection,
    setActiveSection
    // homeRef,
    // learnMoreRef,
    // startCounterRef,
    // successStoriesRef,
    // startQuoteRef,
  } = useContext(ObserverContext);

  let isSliderVisible;
  let isServicesVisible;
  let isTeamVisible;
  let isWorkVisible;
  let isVerticalsVesible;
  let isMethodologyVisible;
  let isContactVisible;

  // window.location.pathname
  // /database-services
  // /erp-services

  useEffect(() => {

  //  setTimeout(() => {

  //     // isSliderVisible = IntersectionObserverHook(sliderRef);
  //     // isServicesVisible = IntersectionObserverHook(serviceRef);
  //     // isTeamVisible = IntersectionObserverHook(teamRef);
  //     // isWorkVisible = IntersectionObserverHook(startPortfolioRef);
  //     // isVerticalsVesible = IntersectionObserverHook(verticalsRef);
  //     // isMethodologyVisible = IntersectionObserverHook(methodologyRef);
  //     // isContactVisible = IntersectionObserverHook(contactRef);
    
  //     // console.log("Values for check = ", isSliderVisible, isServicesVisible, isTeamVisible, isWorkVisible, isVerticalsVesible, isMethodologyVisible, isContactVisible);
  //  }, 1000);

  //  console.log('this is navigation', navigation);
  //  const onHashChange = () => {
  //     //  setRouteHash(window.location.hash);
  //     console.log('this is navbar useEffect', window.location.hash);
  //  };

  //  window.addEventListener('hashchange', onHashChange);

  //  return () => {
  //      window.removeEventListener('hashchange', onHashChange);
  //  };

  //  if () any of threee activeSetion set service.
  console.log(window.location.pathname,'hhhhhhhhhhhhhhhhhhhhhhhh')
  if(window.location.pathname === '/database-services' || '/erp-services' || '/custom-development')
{
  setActiveSection('services');
}

if(_.startsWith(window.location.pathname, '/read-more')){
  setActiveSection('work')
}
},[]);
  



  const hamMneuRef = useRef();

  const toggleNav = () => {
    hamMneuRef.current.classList.toggle("side-menu-active");
  };

  return (
    // <p>This is Navbar Page</p>
    <header className="navigation-bar">
      <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top navbar-fixed-top">
        <div className="container">
          <a href="/" className="navbar-brand position-relative">
            <img src={latestlogo} alt="image" style={{height:'60px',width:'70px', marginLeft: "11px", marginBottom: "8px"}}/>
            <span className="text-white position-absolute" style={{ fontSize: "8px", bottom: "-5px", left:  "0px" }}>Striving For Excellence</span>
          </a>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className={"nav-item " + (activeSection === 'home'? 'active': '')}>
                <a className={"nav-link  " + (activeSection === 'home'? 'active': '')} href="/">
                  Home
                </a>
              </li>
              <li className={"nav-item " + (activeSection === 'services'? 'active': '')}>
                <a className={"nav-link  " + (activeSection=== 'services'? 'active': '')} href ='/#services'>
                  Services
                </a>
              </li>
              <li className={"nav-item " + (activeSection === 'accomplishments' ? 'active': '')}>
                <a
                  className={"nav-link  " + (activeSection === 'accomplishments' ? 'active': '')}
                  href="/#accomplishments"
                  
                >
                  Accomplishments
                </a>
              </li>
              <li className={"nav-item " + (activeSection === 'team' ? 'active': '')}>
                <a className={"nav-link  " + (activeSection === 'team' ? 'active': '')} href ='/#team' >
                  Team
                </a>
              </li>
              <li className={"nav-item " + (activeSection === 'projects' ? 'active': '')}>
                <a className={"nav-link  " + (activeSection === 'projects' ? 'active': '')} href="/#projects" >
                  Projects
                </a>
              </li>
              <li className={"nav-item " + (activeSection === 'alliances' ? 'active': '')}>
                <a className={"nav-link  " + (activeSection === 'alliances' ? 'active': '')} href="/#alliances" >
                  Alliances
                </a>
              </li>
              <li className={"nav-item " + (activeSection === 'contact' ? 'active': '')}>
                <a className={"nav-link  " + (activeSection === 'contact' ? 'active': '')} href="/#contact" >
                  Contact
                </a>
              </li>
              
              <li className={"nav-item " + (activeSection === 'privacy-policy' ? 'active': '')}>
                <a className={"nav-link  " + (activeSection === 'privacy-policy' ? 'active': '')}   href="/privacy-policy" >
                  Privacy
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="social-icons">
            <ul>
               <li><a className="facebook-hovr" href="javascript:void(0);"><FaFacebook></FaFacebook></a></li>
               <li><a className="twitter-hovr" href="javascript:void(0);"><FaTwitter></FaTwitter></a></li>
               <li><a className="instagram-hovr" href="javascript:void(0);"><FaInstagram></FaInstagram></a></li>
            </ul>
         </div> */}
        </div>
        {/* <!-- Side Menu Button --> */}
        <a
          href="javascript:void(0)"
          className="sidemenu_btn link"
          id="sidemenu_toggle"
          onClick={toggleNav}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </nav>

      {/* <!-- Side Menu --> */}
      <div className="side-menu hidden " ref={hamMneuRef}>
        <div className="inner-wrapper">
          <div className="image">
            <img src={latestlogo} style={{height:'60px', width:'80px', marginBottom: "8px"}} alt="image" />
          </div>
          {/* Icon for close. */}
          <MdOutlineCancel className="btn-close" onClick={toggleNav} id="btn_sideNavClose"></MdOutlineCancel>
          {/* <span className="btn-close" onClick={toggleNav} id="btn_sideNavClose">
            <i></i>
            <i></i>
          </span> */}
          <nav className="side-nav w-100">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#accomplishments">
                  Accomplishments
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#team">
                  Team
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#projects">
                  Projects
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#alliances">
                  Alliances
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="#contact">
                  Contact
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link scroll" onClick={toggleNav} href="/privacy-policy">
                  Privacy
                </a>
              </li>
            </ul>
          </nav>
          {/* <div className="side-menu-footer">
            <div className="banner-icons">
              <ul>
                <li>
                  <a href="javascript:void(0);" className="hover">
                    <FaFacebook></FaFacebook>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" className="hover">
                    <FaTwitter></FaTwitter>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" className="hover">
                    <FaLinkedin></FaLinkedin>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" className="hover">
                    <FaInstagram></FaInstagram>
                  </a>
                </li>
              </ul>
            </div>
            <p className="copywrite text-left">
              © 2023 Access Infotech Solution. Made With Love by Dev Inventiv
              Solutions.
            </p>
          </div> */}
        </div>
      </div>
      <a id="close_side_menu" href="javascript:void(0);"></a>
    </header>
  );
}

export default Navbar;
